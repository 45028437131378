@import '../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../styles/mixin';
@import '../../styles/global';

.top-image-modal {
  .reference-top-image-modal-area {
    .input-area {
      display: flex;
      flex-flow: column;
      justify-content: flex-start;
      align-items: flex-start;
      row-gap: 22px;
      padding-top: 22px;
      .reference-area, .check-area, .image-area {
        margin: 0;
        width: 100%;
      }
      .detail-area, .button-container {
        width: 100%;
      }
    }
  }
  .pre-label {
    width: 130px;
    font-size: $font-size-ssm;
    display: inline-block;
    &.label-required {
      @include requiredItem();
    }
  }
  .check-area {
    margin: 25px 0 10px 0;
    align-items: center;
    display: flex;
    .nf-checkbox-wrapper {
      display: flex;
      div {
        margin-right: 15px;
        label {
          font-size: $font-size-ssm;
          &::before {
            font-size: $font-size-sssm;
          }
        }
      }
    }
    .push-detail {
      margin-left: 15px;
      display: flex;
      flex-direction: column;
      font-size: $font-size-ssssm;
      flex: 1;
      color: $dark-grey;
      font-weight: normal;
    }
  }
  .date-area {
    display: flex;
    margin-top: 25px;
    align-items: center;
    position: relative;
    .period {
      min-width: 130px;
    }
    .label-required {
      margin-bottom: 2px;
    }
  }
  .image-area {
    display: flex;
    flex-direction: row;
    margin-top: 25px;
    margin-bottom: 25px;
    .image-border {
      border-radius: 2px;
      overflow: hidden;
      background-color: #f5f5f5;
    }
    .image-select {
      position: relative;
      height: 200px;
      width: 150px;
      background-color: $grey;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      border-radius: 2px;
      overflow: hidden;
      .tc-btn {
        width: 118px;
        border-width: 2px;
        margin: 0;
        margin-bottom: 17px;
        font-size: $font-size-ssm;
      }
    }
  }
  .detail-area-title {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    border-top: solid 1px #efe6e5;
    border-bottom: solid 1px #efe6e5;
    cursor: pointer;
  }
  .detail-area {
    transition: 0.3s;
    height: auto;
    &.hidden {
      display: none;
      height: 0;
    }
    .input-textarea {
      position: relative;
      margin-bottom: 45px;
      textarea {
        height: 130px;
      }
      .tc-btn {
        position: absolute;
        font-size: 12px;
        top: 20px;
        margin-left: 0;
        width: 100px;
        height: 25px;
        line-height: 25px;
        background-color: #c4a6a9;
      }
    }
    .image-area {
      display: flex;
      margin-top: 60px;
      align-items: center;
      justify-items: center;
      label {
        align-self: flex-start;
        padding-top: 5px;
        display: inline-block;
        font-size: $font-size-ssm;
        min-width: 130px;
      }
      .uploadImageBox {
        position: relative;
        display: inline-block;
        background-color: $grey;
        width: 180px;
        height: 200px;
        padding-bottom: '3rem';
        border-radius: 2px;
        overflow: hidden;
        &.empty {
          background-color: white;
        }
      }
      .uploadImageRow {
        display: flex;
        justify-content: space-between;
      }
      .imageText {
        text-align: center;
      }
    }
  }
  .reference-area {
    display: flex;
    align-items: center;
    font-size: $font-size-sm;
    margin: 22px 0;
  }
  .font-sm {
    font-size: $font-size-sm;
  }
  .date-area-error {
    font-size: $font-size-sssm;
    position: absolute;
    top: -20px;
    color: red;
    &.from {
      left: 260px;
    }
    &.to {
      left: 550px;
    }
  }
  .close-icon {
    position: absolute;
    right: 3px;
    top: 3px;
    font-size: 18px !important;
    z-index: 1;
  }
}
