@import '../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../styles/mixin';
@import '../../styles/global';

.notification-modal,
.reserve-modal {

  .geo-push-input-area {
    width: 100%;

    .map-element_area {
      width: 100%;
      margin-top: 30px;
      .map-element_select-field-area {
        .map-element_select-field {
          align-items: flex-start;
          .map-element_select-field-label {
            width: 130px;
          }
          .map-element_select-field-label::after {
            content: '';
          }
        }
        .tc-btn-black {
          margin-left: 130px;
          width: 226px
        }
      }
    }
  }



  .font-sm {
    font-size: $font-size-sm;
  }
  .input-textarea {
    position: relative;
    margin-bottom: 45px;
    textarea {
      height: 130px;
    }
    .tc-btn {
      position: absolute;
      font-size: 12px;
      top: 20px;
      margin-left: 0;
      width: 100px;
      height: 25px;
      line-height: 25px;
      background-color: #c4a6a9;
    }
  }
  .input-send-time {
    .tc-input-wrapper.pre-label-exist {
      margin-right: 10px;
    }
  }
  .input-repeat-type,
  .input-send-time {
    .tc-input-info {
      justify-content: flex-end;
    }
  }
  .check-area {
    margin: 30px 0 20px;
    display: flex;
    .nf-checkbox-wrapper {
      display: flex;
      div {
        margin-right: 15px;
        label {
          font-size: $font-size-ssm;
          &::before {
            font-size: $font-size-sssm;
          }
        }
      }
    }
  }
  .shop-apply-btn {
    margin: -15px 0px 130px 0px;
  }
  .input-file {
    display: flex;
    margin: 30px 0 80px;
    align-items: flex-start;
    .input-file-label {
      margin-bottom: 15px;
      display: inline-block;
    }
  }
  .pre-label {
    width: 130px;
    font-size: $font-size-ssm;
    display: inline-block;
    &.label-required {
      @include requiredItem();
    }
  }
  .image-area {
    display: flex;
    flex-direction: row;
    margin-top: 25px;
    margin-bottom: 25px;
    .image-border {
      border-radius: 2px;
      overflow: hidden;
    }
    .image-select {
      position: relative;
      height: 200px;
      width: 150px;
      background-color: $grey;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      border-radius: 2px;
      overflow: hidden;
      .tc-btn {
        width: 118px;
        border-width: 2px;
        margin: 0;
        margin-bottom: 17px;
        font-size: $font-size-ssm;
      }
    }

    .close-icon {
      position: absolute;
      right: 3px;
      top: 3px;
      font-size: 18px !important;
      z-index: 1;
    }
  }
  .reference-area {
    display: flex;
    font-size: $font-size-sm;
    margin: 22px 0;
  }
  .cancel-link {
    color: $cardinal;
    font-size: $font-size-sssm;
    text-decoration: underline;
    position: absolute;
    bottom: 40px;
    right: 30px;
  }
  .add-btn-area {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    .plus-icon {
      width: 28px;
      height: 28px;
      &:hover {
        cursor: pointer;
      }
    }
  }
  .button-container {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  .ant-table-cell {
    .tc-input-wrapper {
      margin-bottom: 29px;
    }
    .table-cell-icon {
      margin-top: 8px;
    }
  }
}
